/* html,
body {
  overflow: scroll;
  overflow-x: hidden;
  height: 100%;
  
}
::-webkit-scrollbar {
  display: none;
} */

body {
	margin: 0;
	width: 100%;
	height: 100vh;
	font-family: "Exo", sans-serif;
	color: #fff;
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradientBG 15s ease infinite;
}

@keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

a,
a:hover {
	text-decoration: none;
	color: #ddd;
}

.social-icons {
	height: 90px;
	position: absolute;
	bottom: 0;
	width: 100%;
	left: 20%;
	transform: translate(-50%,-50%);
	display: flex;
	justify-content: center;
	align-items: center; 
	padding-left: 10%;
	/* box-shadow: 0px 35px 30px -30px #777; */
  }
  
  span.individual-icons {
	margin: 0 20px;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: #ff0;
	line-height: 50px !important;
	text-align: center;
	color: snow;
	font-size: 25px !important;
	box-shadow: 1px 1px 1px -1px rgba(0,0,0,0.3);
	/* opacity: 0.85; */
	cursor: pointer;
	position: relative;
	overflow: hidden;
	transition: all 0.3s ease;
	&:hover {
	  opacity: 1;
	}
  }
  span.individual-icons:before{
	position: relative;
	z-index: 1;
  }
  span.individual-icons:nth-of-type(1) {
	background: #EA4335;
  }
  span.individual-icons:nth-of-type(2) {
	background: #191919 ;
  }
  span.individual-icons:nth-of-type(3) {
	background: #4867AA;
  }
  span.individual-icons:nth-of-type(4) {
	background: #800080;
  }
  span.individual-icons:nth-of-type(5) {
	background: #00ACED;
  }
  span.individual-icons:nth-of-type(6) {
	background: #EA4C89;
  }
  
  .after-span .nav-btn {
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.25);
	border-radius: 50%;
	transform: scale(0);
	transition: transform 0.75s ease-in;
  }

  span.individual-icons:hover .after-span.ripple .nav-btn.ripple {
	  transform: scale(1);
	  transition: transform 1s ease-out;
  }
  
  @media (max-width: 740px){
	span.individual-icons {
	  margin: 0 8px;
	}
  }
  @media (max-width: 500px){
	.social-icons {
	  flex-wrap: wrap;
	  height: auto;
	  padding: 10px;
	}
	span.individual-icons {
	  margin: 10px;
	}
	
  }
  
  /* .nav-btn {
	
	position: fixed;
	display:block;
  	height: 5px;
  	width: 3px;
  	border-radius: 50%;
  	border: 1px solid red;
  } */

/*--Button Styles--*/
.nav-btn {
	top : 0;
	right : 0;
	position: fixed;
	color: #D1C4E9;
	background-color: #242526;
	height: 50px;
	width: 50px;
	border: none;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	z-index: 9999;
  }

  .nav-btn:before {
	content: '';
	position: absolute;
	background: #3A3B3C;
	background-color: #3A3B3C;
	width: 0;
	height: 0;
	z-index: -1;
	border-radius: 50%;
	box-shadow: 0px 0px 24px 1px rgba(201, 192, 201, 0.2);
	transition: all 900ms cubic-bezier(1.000, -0.195, 0.000, 1.330);
	transition-timing-function: cubic-bezier(1.000, -0.195, 0.000, 1.330);
  }
  
  .nav-btn:hover:before {
	width: 85%;
	height: 85%;
  }
  
  .nav-btn:active:before, .btn:focus:before {
	width: 100%;
	height: 100%;
  }

  .navigation-bar {
	position: fixed;
	right: 0;
	background-color: #3A3B3C;
	height: 100%;
	width: 50px;
	overflow: hidden;
  	display: table;
	margin: auto;
	/* display: none */
  }

  ul {
	list-style-type: none;
  }

  .nav-button {
	text-align: center;
  	margin: 0 auto;
  	display: table-cell;
  	vertical-align: middle;
  	transform: rotate(270deg);
  	overflow: hidden;
  	color: white;
	letter-spacing: 2px;
	font-size: 22px;
  }

  .nav-element {
	padding-top: 5px;
	padding-bottom: 5px;
  }

  span.description-animated-text::before {
	content: "A Freelance Developer";
	animation: animatedDescription infinite 7s;
	/* padding-left: 10px; */
}

@keyframes animatedDescription {

	25% {
		content: "Expertise in MERN stack";
	}

	50% {
		content: "Lead a very passionate team";
	}

	75% {
		content: "Believes in Work hard and Enjoy harder";
	}
}

.spinner {
	box-sizing: border-box;
  margin: 10px 10px;
  padding: 10px 10px;
  display: grid;
  place-items: center;
  min-height: 100vh;
}

.paytm-loader {
	color: #002e6e;
	width: 3px;
	aspect-ratio: 1;
	border-radius: 50%;
	box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
	transform: translateX(-38px);
	animation: loader 0.5s infinite alternate linear;
	-webkit-box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
	-webkit-transform: translateX(-38px);
	-webkit-animation: loader 0.5s infinite alternate linear;
  }
  
  @keyframes loader {
	50% {
	  box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;
	}
	100% {
	  box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;
	}
  }
  
  @-webkit-keyframes loader {
	50% {
	  box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;
	}
	100% {
	  box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;
	}
  }
  
  button:not(:disabled).carousel-control-next button:not(:disabled).carousel-control-prev {
	position: fixed;
  }

  .card-body {
	position: fixed;
	top: 25%;
	left: 25%;
	right: 25%;
	margin-top: -50px;
	margin-left: -50px;
	width: 50%;
	height: 75%;
	background-color: #ddd;
  }

  .new-main-nav {
	padding-top: 10%;
	right: 0;
	width:50px;
	height:100%;
	position: fixed !important;
	background:#4c4c4c;
	z-index: 100;
  }
  ul.new-nav {
	width:21px;
	margin:0 auto;
  }
  /* ul.new-nav li:first-child {
	padding-top:35px
  } */
  /* ul.new-nav li {
	padding-top:124px
  } */
  ul.new-nav li a {
	padding-right: 20px;
	writing-mode: vertical-rl;
	text-orientation: sideways-right;
	text-decoration: none;
	padding-top: 25px;
	width:21px;
	color:#D1C4E9;
  }

  .social-media-button-group-icon {
	color: white;
	text-decoration: none;
  }