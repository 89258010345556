.meImg {
    right: 0;
    top: 0;
    position: fixed;
    width: 45%;
    height: 100%;
    padding-right: 4%;
  }

  .intro {
    top: 0;
    left: 0;
    padding-top: 10%;
    padding-left: 12%;
    color: #ffffff;
  }

.salutation {
    padding: 25px 25px 15px 20px;     
}

.meIntro {
    padding-left: 20px;
    z-index: 100;
}

.buttonGroups {
  padding: 20px;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}

a,
a:active,
a:focus {
    color: #6f6f6f;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
img {
max-width: 100%;
height: auto;
}
section.about-section {
    padding: 60px 0;
   /* min-height: 100vh;*/
}

.sec-title{
position:relative;
z-index: 1;
margin-bottom:60px;
}

.sec-title .title {
position: relative;
display: block;
text-decoration: underline;
text-decoration-thickness: 10%;
/* font-size: 18px;
line-height: 24px; */
color: #ffffff;
font-weight: 500;
margin-bottom: 15px;
}

.sec-title h3.tag-line{
position: relative;
display: block;
/* font-size:40px;
line-height: 1.28em; */
color: #222222;
font-weight: 600;
padding-bottom:18px;
}

.sec-title h2:before{
position:absolute;
content:'';
left:0px;
bottom:0px;
width:50px;
height:3px;
background-color:#d1d2d6;
}

.sec-title .text{
position: relative;
font-size: 16px;
line-height: 26px;
color: #848484;
font-weight: 400;
margin-top: 35px;
}

.sec-title.light h2{
color: #ffffff;
}

.sec-title.text-center h2:before{
left:50%;
margin-left: -25px;
}

.list-style-one{
position:relative;
}

.list-style-one li{
position:relative;
font-size:16px;
line-height:26px;
color: #222222;
font-weight:400;
padding-left:35px;
margin-bottom: 12px;
}

.list-style-one li:before {
content: "\f058";
position: absolute;
left: 0;
top: 0px;
display: block;
font-size: 18px;
padding: 0px;
color: #ff2222;
font-weight: 600;
-moz-font-smoothing: grayscale;
-webkit-font-smoothing: antialiased;
font-style: normal;
font-variant: normal;
text-rendering: auto;
line-height: 1.6;
font-family: "Font Awesome 5 Free";
}

.list-style-one li a:hover{
color: #44bce2;
}

.btn-style-one{
position: relative;
display: inline-block;
font-size: 17px;
line-height: 30px;
color: #ffffff;
padding: 10px 30px;
font-weight: 600;
overflow: hidden;
letter-spacing: 0.02em;
background-color: orange;
}

.btn-style-one:hover{
background-color: rgb(99, 64, 0);
color: #ffffff;
}
.about-section{
position: relative;
padding: 120px 0 70px;
}

.about-section .sec-title{
margin-bottom: 45px;
}

.about-section .content-column{
position: relative;
margin-bottom: 50px;
}

.about-section .content-column .inner-column{
position: relative;
padding-left: 30px;
}

.about-section .text{
margin-bottom: 20px;
font-size: 16px;
line-height: 26px;
color: #d4d2d2;
font-weight: 400;
}

.about-section .list-style-one{
margin-bottom: 45px;
}

.about-section .btn-box{
position: relative;
}

.about-section .btn-box a{
padding: 15px 50px;
}

.about-section .image-column{
position: relative;
}

.about-section .image-column .text-layer{
position: absolute;
right: -110px;
top: 50%;
font-size: 325px;
line-height: 1em;
color: #ffffff;
margin-top: -175px;
font-weight: 500;
}

.about-section .image-column .inner-column{
position: relative;
padding-left: 80px;
padding-bottom: 0px;
}
.about-section .image-column .inner-column .author-desc{
position: absolute;
bottom: 16px;
z-index: 1;
background: orange;
padding: 10px 15px;
left: 96px;
width: calc(100% - 152px);
border-radius: 50px;
}
.about-section .image-column .inner-column .author-desc h2{
font-size: 21px;
letter-spacing: 1px;
text-align: center;
color: #fff;
margin: 0;
}
.about-section .image-column .inner-column .author-desc span{
font-size: 16px;
letter-spacing: 6px;
text-align: center;
color: #fff;
display: block;
font-weight: 400;
}
.about-section .image-column .inner-column:before{
content: '';
position: absolute;
width: calc(50% + 80px);
height: calc(100% + 160px);
top: -80px;
left: -3px;
background: transparent;
z-index: 0;
border: 44px solid orange;
}

.about-section .image-column .image-1{
position: relative;
}
.about-section .image-column .image-2{
position: absolute;
left: 0;
bottom: 0;
}

.about-section .image-column .image-2 img,
.about-section .image-column .image-1 img{
box-shadow: 0 30px 50px rgba(8,13,62,.15);
border-radius: 46px;
}

.about-section .image-column .video-link{
position: absolute;
left: 70px;
top: 170px;
}

.about-section .image-column .video-link .link{
position: relative;
display: block;
font-size: 22px;
color: #191e34;
font-weight: 400;
text-align: center;
height: 100px;
width: 100px;
line-height: 100px;
background-color: #ffffff;
border-radius: 50%;
box-shadow: 0 30px 50px rgba(8,13,62,.15);
-webkit-transition: all 300ms ease;
-moz-transition: all 300ms ease;
-ms-transition: all 300ms ease;
-o-transition: all 300ms ease;
transition: all 300ms ease;
}

.about-section .image-column .video-link .link:hover{
background-color: #191e34;
color: #fff
}

textarea {
  width: 100%;
  height: 100%;
}

.input-form {
  padding: 2%;
}

.button-on {
  padding-top: 5%;
  padding-left: 2%;
}

.submit-button {
  color: #fff;
}

.modalContent {
  width:80%;
  height:80%;
  align-self: center;
}

.projects-container {
  max-width: 1400px;
  padding: 0 15px;
  margin: 0 auto;
  padding-top: 25px;
}

.project-card-body-structure {
  height: 400px;
  width: 350px;
}

.projects-cards {
  display: flex;
  padding: 25px 0px;
  list-style: none;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.projects-card {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  padding: 20px;
  background: greenyellow;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 15%);
  scroll-snap-align: start;
  transition: all 0.projects-2s;
}

.projects-card:not(:last-child) {
  margin-right: 10px;
}

.projects-card:hover {
  color: greenyellow;
  background: orange;
}

.projects-card .projects-card-title {
  font-size: 20px;
}

.projects-card .projects-card-content {
  margin: 20px 0;
  max-width: 85%;
}

.projects-card .projects-card-link-wrapper {
  margin-top: auto;
}

.projects-card .projects-card-link {
  display: inline-block;
  text-decoration: none;
  color: white;
  background: orange;
  padding: 6px 12px;
  border-radius: 8px;
  transition: background 0.2s;
}

.projects-card:hover .projects-card-link {
  background: rgb(73, 50, 6);
}

.projects-cards::-webkit-scrollbar {
  height: 12px;
}

.projects-cards::-webkit-scrollbar-thumb,
.projects-cards::-webkit-scrollbar-track {
  border-radius: 92px;
}

.projects-cards::-webkit-scrollbar-thumb {
  background: rgb(73, 50, 6);
}

.projects-cards::-webkit-scrollbar-track {
  background: #eaf39b;
}

@media (min-width: 500px) {
  .projects-card {
    flex-basis: calc(50% - 10px);
  }

  .projects-card:not(:last-child) {
    margin-right: 20px;
  }
}

@media (min-width: 700px) {
  .projects-card {
    flex-basis: calc(calc(100% / 3) - 20px);
  }

  .projects-card:not(:last-child) {
    margin-right: 30px;
  }
}

@media (min-width: 1100px) {
  .projects-card {
    flex-basis: calc(25% - 30px);
  }

  .projects-card:not(:last-child) {
    margin-right: 40px;
  }
}

h1.project-title {
  text-align: center;
  text-decoration: underline;
  text-decoration-thickness: 10%;
  /* font-size: 18px;
  line-height: 24px; */
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 15px;
}